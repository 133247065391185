<template>
  <div class="AppBlogArcticle" data-aos="fade-down">
    <a :href="href"
      ><app-title
        class="AppBlogArcticle__title title title--small title--montserrat title--W500"
        >{{ title }}</app-title
      ></a
    >
    <img class="AppBlogArcticle__image"
         :src="imageUrl" alt="blog-img">
    <div class="AppBlogArcticle__banner" :class="{ wide: wide }">
      <app-subtitle
        class="AppBlogArcticle__subtitle subtitle subtitle--medium"
        @click="getIsmobyle()"
        >{{ description }}</app-subtitle
      >
      <app-show-more
        class="AppBlogArcticle__showmore"
        :class="{ wide: wide }"
        :href="href"
      />
    </div>
  </div>
</template>

<script>
import AppTitle    from '../../../components/AppTitle.vue';
import AppSubtitle from '../../../components/AppSubtitle.vue';
import AppShowMore from '../../../components/AppShowMore.vue';

export default {
  name: 'AppBlogArcticle',
  components: {
    AppTitle,
    AppSubtitle,
    AppShowMore,
  },
  props: {
    imageUrl: String,
    title: String,
    subtitle: String,
    href: String || '#',
    customWidth: String,
    description: String,
    long: Boolean,
    wide: Boolean,
  },

  data() {
    return {
      width: null,
    };
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
};
</script>

<style lang="scss">
.AppBlogArcticle {
  max-width: 285px;
  @include flex(center, flex-start, column, nowrap);

  @include media-d-m($screen-desktop-large) {
    max-width: 225px;
  }

  @include media-d-m($screen-netbook) {
    border-top: 1px solid $grey;
  }

  &:hover .card__title {
    color: $yellow;
  }
  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    min-height: 200px;

    @include media-d-m($screen-desktop-large) {
      min-height: 158px;
    }

    &.long {
      min-height: 400px;

      @include media-d-m($screen-desktop-large) {
        min-height: 305px;
      }

      @include media-d-m($screen-netbook) {
        min-height: 158px;
      }
    }
  }

  &__banner {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 50px;
    }

    &.wide {
      @include flex(flex-start, flex-start, row, nowrap);
      @include media-d-m($screen-netbook) {
        display: block;
      }
    }
  }
  &__title {
    margin-bottom: 20px;
    transition: color 0.2s ease;
    cursor: pointer;

    @include media-d-m($screen-netbook) {
      margin-top: 20px;
    }
  }

  &__subtitle {
    max-width: 285px;
    margin-bottom: 20px;
    margin-top: 10px;
    @include media-d-m($screen-desktop-large) {
      max-width: 225px;
    }
  }

  &__showmore {
    @include flex(flex-end, center, row, nowrap);
    width: 100%;

    &.wide {
      margin-top: 20px;

      @include media-d-m($screen-netbook) {
        margin-top: 0px;
        @include flex(flex-end, center, row, nowrap);
      }
    }
  }
}
.image {
  object-fit: cover;
}
</style>
