<template>
  <div class="AppBlogBlock">
    <div class="AppBlogBlock__inner">
      <div class="AppBlogBlock__articles AppBlogBlock__article--first p4--4">
        <div class="p2--4">
          <app-blog-article
              v-if="array[0]"
            :title="array[0]['title_' + selectedLang]"
            :imageUrl="this.base_url + '/storage/item-image/' + array[0].image[0].title"
            :description="array[0]['text_' + selectedLang]"
            :href="'#'"
            :class="'blog blog__1'"
          />
        </div>
        <div class="p2--4">
          <app-blog-article
              v-if="array[1]"
              :title="array[1]['title_' + selectedLang]"
            :imageUrl="this.base_url + 'storage/item-image/' + array[1].image[0].title"
            :description="array[1]['text_' + selectedLang]"
            href="#"
            :class="'blog blog__2'"
          />
        </div>
      </div>
      <div class="AppBlogBlock__articles AppBlogBlock__article--second p4--4">
        <div class="p2--4 p2--4--flex-start">
          <app-blog-article
              v-if="array[2]"
              :title="array[2]['title_' + selectedLang]"
            :imageUrl="this.base_url + '/storage/item-image/' + array[2].image[0].title"
            :description="array[2]['text_' + selectedLang]"
            href="#"
            :class="'blog blog__3'"
            :long="true"
          />
        </div>
        <div class="p2--4">
          <app-blog-article
              v-if="array[3]"
              :title="array[3]['title_' + selectedLang]"
            :imageUrl="this.base_url + '/storage/item-image/' + array[3].image[0].title"
            :description="array[3]['text_' + selectedLang]"
            href="#"
            :class="'blog blog__4'"
            :long="true"
            :wide="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBlogArticle from './AppBlogArticle.vue';
import { BASE_URL }   from "@/constants";
import { mapGetters } from "vuex";

export default {
  name: 'AppBlogBlock',
  components: { AppBlogArticle },
  props: {
    array: Array,
  },
  data() {
    return {
      base_url: BASE_URL
    };
  },
  computed: {
    ...mapGetters('lang', ['selectedLang']),
  }
};
</script>

<style lang="scss">
.AppBlogBlock {
  &__articles {
    @include flex(space-between, flex-start, row, nowrap);
  }

  &__articles {
    @include media-d-m($screen-tablet-small) {
      @include flex(center, flex-start, column, nowrap);
    }
  }

  &__article--second {
    height: calc(100% * 2);
  }
}

.p4--4 {
  width: 100%;
}
.p2--4 {
  width: calc(50% - 10px);
  @include flex(flex-end, flex-start, row, nowrap);

  @include media-d-m($screen-netbook) {
    width: 50%;
    padding: 15px;
  }

  @include media-d-m($screen-tablet-small) {
    width: 100%;
  }

  &--flex-start {
    @include flex(flex-start, flex-start, row, nowrap);
  }
}

.blog {
  @include media-d-m($screen-netbook) {
    max-width: 100%;
    width: 100%;
  }
  &__0 {
    position: relative;
    top: 200px;
    @include media-d-m($screen-desktop-large) {
      top: 250px;
    }
    @include media-d-m($screen-netbook) {
      top: 0;
    }
  }

  &__1 {
    position: relative;
    top: 20px;

    @include media-d-m($screen-netbook) {
      top: 0;
    }
  }

  &__2 {
    position: relative;
    top: 200px;
    @include media-d-m($screen-desktop-large) {
      top: 250px;
    }
    @include media-d-m($screen-netbook) {
      top: 0;
    }
  }
  &__3 {
    max-width: 100%;
    width: 100%;
    position: relative;
    top: -33px;

    @include media-d-m($screen-desktop-large) {
      top: auto;
    }
  }
}
</style>
