<template>
  <span>
    <a :href="this.href" class="show-more">
      {{ text['text_' + this.selectedLang]}}
      <span class="show-more__arrow"
        ><icon-arrow class="iconArrow--right iconArrow--light-yellow"
      /></span>
    </a>
  </span>
</template>

<script>
import IconArrow from './svg/iconArrow.vue';
import {mapGetters} from "vuex";

export default {
  name: 'AppShowMore',
  components: { IconArrow },
  data() {
    return {
      text: {
        text_ru: 'Показать больше',
        text_ua: 'Показати більше',
        text_pl: 'Pokaż więcej',
        text_en: 'Show more',
      },
    }
  },
  computed: {
    ...mapGetters(['getIsDropMenuActive', 'getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
  props: {
    href: String || '#',
  },
};
</script>

<style lang="scss">
.show-more {
  @include transition(color);
  &:hover {
    color: $yellow;
  }

  &:hover > .show-more__arrow {
    margin-left: 25px;

    & svg {
      fill: yellow;
    }
  }

  @include text($h18, 400, $light-yellow, $f-montserrat);
  @include media-d-m($screen-desktop-large) {
    font-size: $h15;
  }

  &__arrow {
    margin-left: 5px;

    transition-property: margin-left;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }
}
</style>
