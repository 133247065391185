<template>
  <div class="AppBlog" id="AppBlog">
    <div class="AppBlog__inner container">
      <app-title
        class="AppBlog__title title title--medium"
        data-aos="fade-right"
        >{{ getSortedTexts[0]['text_' + this.selectedLang]}}</app-title
      >
      <app-subtitle class="AppBlog__subtitle" data-aos="fade-right"
        >{{ getSortedTexts[1]['text_' + this.selectedLang]}}</app-subtitle
      >

      <app-blog-block
          v-for="(posts) in getSplitPosts"
          :array="posts"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppBlogBlock   from '../components/AppBlogBlock.vue';
import AppTitle       from '../../../components/AppTitle.vue';
import AppSubtitle    from '../../../components/AppSubtitle.vue';

export default {
  name: 'AppBlog',
  components: { AppBlogBlock, AppTitle, AppSubtitle },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getSectionBlog__1', 'getSplitPosts', 'getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
  created() {
    this.initBlog();
  },
  mounted() {
    setTimeout(() => {
    }, 1000)
  },
  methods: {
    ...mapActions(['initBlog'])
  }
};
</script>

<style lang="scss">
.AppBlog {
  &__inner {
    padding-top: 100px;
    padding-bottom: 200px;

    @include media-d-m($screen-desktop-large) {
      padding-top: 80px;
      padding-bottom: 250px;
    }

    @include media-d-m($screen-netbook) {
      padding-top: 0px;
      padding-bottom: 0;
    }
  }

  &__title {
    max-width: 665px;
    margin-bottom: 20px;
  }

  &__subtitle {
    max-width: 490px;
    margin-bottom: 40px;

    @include media-d-m($screen-desktop-large) {
      margin-bottom: 0px;
    }

    @include media-d-m($screen-netbook) {
      margin-bottom: 92px;
    }
  }
}
</style>
